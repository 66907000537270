/* Footer Styles */
footer {
    background-color: #fff;
    border-top: 1px solid #fafcfe;
    font-weight: 300;
    bottom: 0; /* 하단 고정 */
    }
    
    footer ul, li {
    list-style: none;
    }
    
    footer .footer-inner {
    padding: 60px 0 60px 0;
    margin: 0 auto;
    }
    
    footer .btn-group {
    display: flex;
    justify-content: left;
    }
    
    .footer-btn {
    font-size: 13.5px;
    margin-right: 20px;
    letter-spacing: -0.3px;
    cursor: pointer;
    transition: color 0.3s ease;
    }
    
    .btn-group a.footer-btn {
    color: #555;
    font-weight: 500;
    }
    
    .btn-group a.footer-btn:hover {
    text-decoration: underline;
    }
    
    
    
    footer .footer-profile {
    text-align: left;
    padding: 10px 0 10px 0;
    }
    
    footer .footer-profile span {
    color: #ccc;
    font-size: 13px;
    }
    
    .half-line {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    }
    
    .copyright {
    flex: 1;
    text-align: left;
    color: #333;
    font-size: 13px;
    }
    
    .sns-icon {
    display: inline-block;
    margin-right: 10px;
    margin-top: 3px;
    font-size: 20px;
    color: #ccc;
    transition: color 0.2s ease;
    }
    
    .sns-icon:hover {
    color: #106fff;
    }
    @media (max-width: 640px) {
        
    .footer-btn {
        font-size: 11px;
        margin-right: 20px;
        letter-spacing: -0.3px;
        cursor: pointer;
        transition: color 0.3s ease;
        }


    footer .footer-profile span {
        color: #ccc;
        font-size: 10.5px;
        }
    }