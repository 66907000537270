input {
    width: 100%;
    text-indent: 6px;
    font-size: 16px;
    color: #333;
    }
    
    .poin_box{
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
        border-radius: 12px;
    }
    
    
    .modal-hr {
    border: none;
    border-top: 0.3px solid #aaa;
    }
    
    .section-checkbox .line {
    border: none; 
    border-bottom: 0.01px solid #ccc;
    margin-bottom: 10px;
    }
    
    .close {
    color: #151515;
    font-size: 30px;
    font-weight: 300;
    position: absolute;
    top: 10px;
    right: 25px;
    padding: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, border 0.3s ease;
    z-index: 10;
    }
    
    .close:hover,
    .close:focus {
    color: #f87171;
    text-decoration: none;
    cursor: pointer;
    z-index: 10;
    }
    
    .modal-title .second-close {
    color: #151515;
    font-size: 30px;
    font-weight: 300;
    position: absolute;
    top: 10px;
    right: 25px;
    padding: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, border 0.3s ease;
    z-index: 10;
    }
    
    .modal-title .second-close:hover,
    .modal-title .second-close:focus {
    color: #f87171;
    text-decoration: none;
    cursor: pointer;
    z-index: 10;
    }
    
    .last-modal-title {
    position: relative;
    }
    
    .last-modal-title .third-close {
    color: #151515;
    font-size: 30px;
    font-weight: 300;
    position: absolute;
    top: -15px;
    right: -5px;
    cursor: pointer;
    padding: 5px;
    transition: background-color 0.3s ease, border 0.3s ease;
    z-index: 10;
    }
    
    .third-close:hover,
    .third-close:focus {
    color: #f87171;
    text-decoration: none;
    cursor: pointer;
    z-index: 10;
    }
    
    .fourth-modal-close {
    color: #151515;
    font-size: 30px;
    font-weight: 300;
    position: relative;
    cursor: pointer;
    padding: 5px;
    transition: background-color 0.3s ease, border 0.3s ease;
    z-index: 50;
    float: right;
    top: -15px;
    }
    
    .fourth-modal-close:hover,
    .fourth-modal-close:focus {
    color: #f87171;
    text-decoration: none;
    cursor: pointer;
    z-index: 10;
    }
    
    .fifth-modal-close {
    color: #151515;
    font-size: 30px;
    font-weight: 300;
    position: relative;
    cursor: pointer;
    padding: 5px;
    transition: background-color 0.3s ease, border 0.3s ease;
    z-index: 50;
    float: right;
    top: -15px;
    }
    
    .fifth-modal-close:hover,
    .fifth-modal-close:focus {
    color: #f87171;
    text-decoration: none;
    cursor: pointer;
    z-index: 10;
    }
    
    .next-button {
    background-color: #f87171;
    color: #fff;
    border-radius: 7px;
    width: 100px;
    height: 40px;
    font-family: Pretendard, sans-serif;
    font-size: 15px;
    font-weight: 500;
    line-height: normal;
    border: none;
    cursor: pointer;
    position: absolute;
    bottom: 35px;
    right: 35px;
    overflow: hidden;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
    transition: background 0.3s ease;
    }
    
    .next-button:hover {
    background-color: #f87171; /* 호버 시 배경색 유지 */
    }
    
    .next-button::before {
    content: "";
    position: absolute;
    left: -100%;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.6) 50%, rgba(255, 255, 255, 0) 100%);
    transition: left 0.3s;
    }
    
    .next-button:hover::before {
    left: 100%;
    }
    
    
    /* 생성권 구매 버튼 */
    .purchase-button {
    background-color: #333;
    color: #fff;
    border-radius: 7px;
    width: 100px;
    height: 33px;
    font-family: Pretendard, sans-serif;
    font-size: 13.5px;
    font-weight: normal;
    font-weight: 500;
    border: none;
    cursor: pointer;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
    transition: background 0.3s ease;
    /* 이동 */
    float: right;
    margin-top: 10px;
    }
    
    .purchase-button:hover {
    background-color: #000;
    }
    
    .second-purchase-button {
    background-color: #333;
    color: #fff;
    border-radius: 7px;
    width: 100px;
    height: 33px;
    font-family: Pretendard, sans-serif;
    font-size: 13.5px;
    font-weight: normal;
    font-weight: 500;
    border: none;
    cursor: pointer;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
    transition: background 0.3s ease;
    margin-bottom: 15px;
    }
    
    .second-purchase-button:hover {
    background-color: #000;
    }
    
    .last-purchase-button {
    background-color: #333;
    color: #fff;
    border-radius: 7px;
    width: 100px;
    height: 33px;
    font-family: Pretendard, sans-serif;
    font-size: 13.5px;
    font-weight: normal;
    font-weight: 500;
    border: none;
    cursor: pointer;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
    transition: background 0.3s ease;
    }
    
    .last-purchase-button:hover {
    background-color: #000;
    }
    
    .generate-button {
    border-radius: 7px;
    color: #f87171;
    background-color: #1070ff13;
    font-family: Pretendard, sans-serif;
    font-size: 15px;
    font-weight: 500;
    cursor: pointer;
    border: none;
    width: 16%;
    height: 40px;
    float: right;
    position: relative;
    overflow: hidden;
    }
    
    .generate-button:hover {
    background-color: #1070ff25;
    }
    
    .previous-button {
    color: #666;
    font-weight: 500;
    background-color: #f2f4f6;
    font-size: 15px;
    border-radius: 7px;
    width: 100px;
    height: 40px;
    text-align: center;
    vertical-align: middle;
    border: none;
    cursor: pointer;
    position: absolute;
    left: 40px;
    bottom: 25px;
    transition: background 0.3s ease;
    }
    
    .previous-button:hover {
    background-color: #1070ff25;
    }
    
    .create-button {
    background-color: #f87171;
    color: #fff;
    border-radius: 7px;
    width: 160px;
    height: 40px;
    font-size: 1rem;
    font-weight: 500;
    line-height: normal;
    border: none;
    cursor: pointer;
    position: absolute;
    bottom: 25px;
    right: 35px;
    overflow: hidden;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
    transition: background 0.3s ease;
    text-align: center;
    vertical-align: middle;
    display: inline-block;
    }
    
    .create-button:hover {
    background-color: #f87171;
    }
    
    .create-button::before {
    content: "";
    position: absolute;
    left: -100%;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.6) 50%, rgba(255, 255, 255, 0) 100%);
    transition: left 0.3s;
    }
    
    .create-button:hover::before {
    left: 100%;
    }
    
    .business-previous-button {
    width: 100px;
    height: 40px;
    border-radius: 7px;
    color: #666;
    font-weight: 500;
    background-color: #f2f4f6;
    border: none;
    font-family: Pretendard, sans-serif;
    font-size: 15px;
    line-height: normal;
    cursor: pointer;
    transition: background 0.3s ease;
    }
    
    .business-previous-button:hover {
    background-color: #1070ff25;
    }
    
    .pro-comment {
    color: #666;
    font-size: 13px;
    }
    
    .business-view-button {
    color: #f87171;
    background-color: #1070ff13;
    border-radius: 7px;
    width: 230px;
    height: 40px;
    font-family: Pretendard, sans-serif;
    font-size: 15px;
    font-weight: 500;
    line-height: normal;
    border: none;
    cursor: pointer;
    transition: background 0.3s ease;
    margin-left: 10px;
    overflow: hidden;
    position: relative;
    }
    
    .business-view-button::before {
    content: "";
    position: absolute;
    left: -100%;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.6) 50%, rgba(255, 255, 255, 0) 100%);
    transition: left 0.3s;
    }
    
    .business-view-button:hover::before {
    left: 100%;
    }
    
    .business-next-button {
    display: inline-block;
    background-color: #f87171;
    color: #fff;
    border-radius: 7px;
    width: 230px;
    height: 40px;
    font-family: Pretendard, sans-serif;
    font-size: 15px;
    font-weight: 500;
    line-height: normal;
    border: none;
    cursor: pointer;
    transition: background 0.3s ease;
    margin-left: 10px;
    overflow: hidden;
    position: relative;
    }
    
    .business-next-button::before {
    content: "";
    position: absolute;
    left: -100%;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.6) 50%, rgba(255, 255, 255, 0) 100%);
    transition: left 0.3s;
    }
    
    .business-next-button:hover::before {
    left: 100%;
    }
    
    /* ▦▦▦▦▦▦▦▦▦ 모달 시작 ▦▦▦▦▦▦▦▦▦ */
    .modal {
    display: none;
    z-index: 21;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    overflow: auto;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    }
    
    .modal-content {
    background-color: #ffffff;
    margin: 10% auto;
    padding: 35px;
    border-radius: 10px;
    border: 1px solid #666;
    max-width: 36%;
    position: relative;
    }
    
    .modal-title {
    text-align: left;
    }
    
    .modal-content h1 {
    color: #000;
    font-family: Pretendard;
    font-size: 23px;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 30px;
    }
    
    .modal-content .direction {
    margin-top: 20px;
    color: #666;
    font-size: 16px;
    font-weight: 300;
    }
    
    .modal-content p {
    color: #aaa;
    font-size: 15px;
    font-weight: 350;
    letter-spacing: -0.3px;
    }
    
    .input-container span {
    color: #ff6060;
    font-family: Pretendard;
    font-size: 15px;
    font-weight: 350;
    display: inline-block;
    margin-left: 6px;
    }
    
    .input-container label {
    color: #333;
    font-size: 16px;
    text-align: left;
    display: block;
    text-align: left;
    margin-bottom: 5px;
    margin-left: 5px;
    }
    
    .modal-second-form {
    margin-top: 30px;
    }
    
    .qa-text {
    width: 100%;
    height: 45px;
    border-radius: 7px;
    border: 0.5px solid #ccc;
    padding-left: 6px;
    }
    
    ::placeholder {
    font-family: Pretendard;
    font-size: 15px;
    font-weight: 250;
    text-indent: 6px;
    color: #666;
    }
    
    .input-container {
    margin-bottom: 20px;
    }
    
    /* ▦▦▦▦▦▦▦▦▦ 모달-2 시작 ▦▦▦▦▦▦▦▦▦ */
    .next-modal {
    display: none;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    overflow: auto;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    }
    
    .next-modal-content {
    background-color: #ffffff;
    margin: 10% auto;
    padding: 35px;
    border-radius: 10px;
    border: 1px solid #666;
    max-width: 700px;
    height: 2000px;
    position: relative;
    }
    
    .next-modal-content h1 {
    color: #000;
    font-family: Pretendard;
    font-size: 23px;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 30px;
    }
    
    /* p 스타일 */
    .next-modal-content p {
    color: #333;
    font-size: 16px;
    line-height: 1.5em;
    font-weight: 350;
    letter-spacing: -0.3px;
    }
    
    .next-modal-content .project-form-style {
    color: #333;
    font-size: 16px;
    line-height: 1.5em;
    font-weight: 300;
    letter-spacing: -0.3px;
    display: inline-block;
    padding: 3px 10px;
    border: 1px solid #333;
    border-radius: 5px;
    background-color: #fff;
    text-decoration: none;
    margin-right: 5px;
    }
    
    .section-checkbox .styled-checkbox {
    width: 17px;
    height: 17px;
    margin-right: 5px;
    cursor: pointer;
    vertical-align: middle;
    
    /* 기본 스타일 재정의 */
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    border-radius: 3.5px;
    border: 1.5px solid #ccc;
    background-color: #ffffff;
    transition: background-color 0.3s ease, border 0.3s ease;
    position: relative;
    right: -1px;
    top: -0.5px;
    }
    
    .section-checkbox {
    height: 35px;
    line-height: 35px;
    }
    
    /* 체크박스와 라벨 사이 여백 제거 */
    .section-checkbox .label-text {
    margin-left: -6px;
    display: inline-block;
    vertical-align: middle;
    }
    
    .checkbox-label {
    vertical-align: middle;
    }
    
    .section-checkbox .styled-checkbox:checked {
    background-color: #f87171;
    border: none;
    }
    
    .section-checkbox .styled-checkbox:checked::before {
    content: "\2713"; /* 유니코드 */
    font-size: 16px;
    color: #ffffff;
    position: absolute;
    top: 50%;
    left: calc(50% - 2px);
    transform: translatㅍe(-50%, -50%);
    z-index: 1;
    }
    
    .label-text:hover {
    cursor: pointer;
    }
    
    .input-bg-gr {
    position: relative;
    width: 100%;
    height: 50px;
    border-top: 1px solid #ccc;
    background-color: #1070ff13;
    margin-bottom: 6px;
    }
    
    .section-checkbox .styled-all-checkbox  {
    width: 17px;
    height: 17px;
    cursor: pointer;
    vertical-align: middle;
    /* 기본 스타일 재정의 */
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    border-radius: 3.5px;
    border: 1.5px solid #ccc;
    background-color: #ffffff;
    transition: background-color 0.3s ease, border 0.3s ease;
    position: relative;
    right: -1px;
    top: 5px;
    }
    
    .section-checkbox {
    height: 35px;
    line-height: 35px;
    }
    
    .checkbox-label {
    vertical-align: middle;
    }
    
    .section-checkbox .styled-all-checkbox:checked {
    background-color: #f87171;
    border: none;
    }
    
    .section-checkbox .styled-all-checkbox:checked::before {
    content: "\2713";
    font-size: 16px;
    color: #ffffff;
    position: absolute;
    top: 50%;
    left: calc(50% - 3px);
    transform: translate(-50%, -50%);
    z-index: 1;
    }
    
    .section-checkbox .styled-checkbox:checked {
    background-color: #f87171;
    border: none;
    }
    
    .section-checkbox .styled-checkbox:checked::before {
    content: "\2713"; /* 체크 표시(체크 마크)의 유니코드 */
    font-size: 16px;
    color: #ffffff;
    position: absolute;
    top: 50%;
    left: calc(50% - 3px);
    transform: translate(-50%, -50%);
    z-index: 1;
    }
    
    .checked-all-text {
    cursor: pointer;
    font-size: 16px;
    color: #f87171;
    font-family: Pretendard;
    font-weight: 500;
    top: 7px;
    left: 30px;
    display: inline-block;
    position: absolute;
    }
    
    .bold-text {
    font-size: 16px;
    color: #a3a3a3;
    font-weight: 300;
    margin-left: 3px;
    display: inline-block;
    padding: 5px 0;
    }
    
    .medium-text {
    font-size: 16px;
    color: #000;
    display: inline-block;
    margin-left: 5.5px;
    }
    
    .medium-text span{
    font-size: 16px;
    color: #f87171;
    display: inline-block;
    }
    
    .label-text {
    cursor: pointer;
    font-size: 15px;
    color: #000;
    font-weight: 300;
    transition: text-decoration 0.3s ease;
    }
    
    /* ▦▦▦▦▦▦▦▦▦ 모달-3 스타일링 시작 ▦▦▦▦▦▦▦▦▦ */
    .last-modal {
    display: none;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    overflow: auto;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    }
    
    .last-modal-content {
    background-color: #ffffff;
    margin: 5.5% auto;
    padding: 35px;
    border-radius: 10px;
    border: 1px solid #666;
    max-width: 56%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    animation-name: modalopen;
    animation-duration: 0.4s;
    }
    
    .last-modal-content h1 {
    color: #000;
    font-family: Pretendard;
    font-size: 23px;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 30px;
    }
    
    .last-modal-content .project-form-style {
    color: #333;
    font-size: 16px;
    line-height: 1.5em;
    font-weight: 300;
    letter-spacing: -0.3px;
    display: inline-block;
    padding: 3px 10px;
    border: 1px solid #333;
    border-radius: 5px;
    background-color: #fff;
    text-decoration: none;
    margin-right: 7px;
    }
    
    .last-modal-content .title-set {
    color: #333;
    font-size: 16px;
    line-height: 1.5em;
    font-weight: 350;
    letter-spacing: -0.3px;
    margin-top: 16px;
    }
    
    .blue-title {
    font-size: 16px;
    color: 000;
    font-weight: 400;
    display: block;
    padding: 20px 0 20px 0 ;
    }
    
    /* = 2번째 모달 미디엄 블루 */
    .blue-title span {
    font-size: 16px;
    color: #f87171;
    }
    
    .small-bullet {
    display: inline-block;
    width: 5px;
    height: 5px;
    background-color: #000;
    border-radius: 100%;
    margin-right: 5px;
    margin-bottom: 3.3px;
    }
    
    .half-block {
    display: flex;
    text-align: left;
    }
    
    .page-btn-container {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    margin-right: 13px;
    }
    
    .page-btn-container button {
    padding: 3px 5px;
    font-size: 10x;
    background-color: transparent;
    border: 1px solid #ccc;
    color: #ccc;
    cursor: pointer;
    border-radius: 3px;
    }
    
    #count {
    font-size: 10px;
    margin: 0 5px;
    color: #666;
    }
    
    .coupon-deduction {
    width: 60px;
    float: right;
    height: 30px;
    border-radius: 7px;
    color: #666;
    background-color: #f3f6e0;
    font-family: Pretendard, sans-serif;
    font-size: 13px;
    font-weight: 350;
    border: none;
    text-align: center;
    line-height: 30px;
    margin-right: 10px;
    }
    
    .last-recreate-button {
    float: right;
    width: 100px;
    height: 30px;
    border-radius: 7px;
    color: #f87171;
    background-color: #1070ff13;
    font-family: Pretendard, sans-serif;
    font-size: 13px;
    font-weight: 500;
    line-height: normal;
    border: none;
    cursor: pointer;
    transition: background .2s ease;
    }
    
    .last-recreate-button:hover {
    background-color: #f87171;
    }
    
    .modal-bt-btn {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    margin: 50px 0 50px 0;
    }
    
    .left-buttons {
    float: left;
    }
    
    .right-buttons {
    float: right;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    }
    
    .right-buttons button {
    margin-bottom: 10px;
    }
    
    .right-buttons .coupon-paid {
    width: 60px;
    display: inline-block;
    height: 30px;
    border-radius: 7px;
    color: #666;
    background-color: #f3f6e0;
    font-family: Pretendard, sans-serif;
    font-size: 13px;
    font-weight: 350;
    border: none;
    text-align: center;
    line-height: 30px;
    margin-right: -5px;
    }
    
    .right-buttons .coupon-free {
    width: 60px;
    display: inline-block;
    height: 30px;
    border-radius: 7px;
    color: #666;
    background-color: #f3f6e0;
    font-family: Pretendard, sans-serif;
    font-size: 13px;
    font-weight: 350;
    border: none;
    text-align: center;
    line-height: 30px;
    margin-right: -5px;
    }
    
    /* = 2번째 모달 체크박스 텍스트 */
    .black-sub-title {
    flex: 1;
    font-size: 15px;
    color: #000;
    font-weight: 300;
    margin-left: 3px;
    display: inline-block;
    margin-top: 5px;
    }
    
    .custom-bullet {
    list-style-type: none;
    padding-left: 20px;
    }
    
    .custom-bullet li::before {
    content: "\2022";
    color: #000;
    font-size: 18px;
    margin-right: 5px;
    }
    
    .result-box {
    border: 1px solid #ccc;
    padding: 13px;
    margin-bottom: 20px;
    border-radius: 10px;
    }
    
    .result-content {
    font-size: 15px;
    line-height: 1.25;
    font-weight: 300;
    }
    
    .modal-bt-btn {
    display: block;
    padding: 10px 0 50px 0;
    }
    
    .sample-chart-img {
    width: 60%;
    height: auto;
    }
    
    /* 네번째 모달 */
    .business-plan-modal {
    display: none;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    overflow: auto;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    }
    
    /* 1안 */
    .business-plan-content {
    background-color: #fff;
    margin: 5.5% auto;
    padding: 35px;
    border-radius: 10px;
    border: 1px solid #666;
    max-width: 56%;
    height: 750px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    animation-name: modalopen;
    animation-duration: 0.4s;
    }
    
    
    
    /* 2안 */
    /* .business-plan-content {
    background-color: #fff;
    margin: 3.7% auto;
    padding: 35px;
    border: 1px solid #666;
    max-width: 100%;
    height: 100%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    animation-name: modalopen;
    animation-duration: 0.4s;
    } */
    
    
    
    
    /* 폼 컨테이너 스타일 */
    .fourth-custom-checkbox {
    width: 100%;
    max-width: 450px;
    height: 415px;
    margin: auto;
    padding: 20px;
    background: #fbfbfb;
    border: 1px solid #ccc;
    border-radius: 20px;
    margin-top: 60px;
    margin-bottom: 60px;
    }
    
    /* 폼 그룹 스타일 */
    .fourth-form-group {
    margin-top: 30px;
    }
    
    /* 라벨 스타일 */
    .fourth-label {
    color: #333;
    font-size: 16px;
    text-align: left;
    display: block;
    text-align: left;
    margin-bottom: 10px;
    margin-left: 5px;
    }
    
    /* 인풋 스타일 */
    .fourth-input {
    width: 100%;
    padding: 10px;
    height: 45px;
    border-radius: 7px;
    border: 0.5px solid #ccc;
    box-sizing: border-box;
    
    }
    
    /* 선택 상태 스타일 */
    .fourth-input:focus {
    outline: none;
    border-color: #007bff;
    }
    
    /* 제출 버튼 스타일 */
    .fifth-submit-button {
    display: block;
    float: right;
    width: 100%;
    padding: 10px;
    background-color: #f87171;
    color: #fff;
    border-radius: 7px;
    width: 100px;
    height: 40px;
    font-family: Pretendard, sans-serif;
    font-size: 15px;
    font-weight: 500;
    line-height: normal;
    border: none;
    cursor: pointer;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
    transition: background 0.3s ease;
    }
    
    /* 제출 버튼 호버 효과 */
    .fifth-submit-button:hover {
    background: #0056b3;
    }
    
    .fourth-previous-button{
    width: 100px;
    height: 40px;
    border-radius: 7px;
    color: #666;
    font-weight: 500;
    background-color: #f2f4f6;
    border: none;
    font-family: Pretendard, sans-serif;
    font-size: 15px;
    line-height: normal;
    cursor: pointer;
    transition: background 0.3s ease;
    }
    
    .fourth-previous-button:hover {
    background-color: #1070ff25;
    }
    
    .fourth-modal-title h1 {
    color: #000;
    font-family: Pretendard;
    font-size: 23px;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 30px;
    }
    
    .fourth-modal-title h3 {
    color: #aaa;
    font-size: 15px;
    font-weight: 350;
    letter-spacing: -0.3px;
    }
    
    /* 다섯번째 모달 */
    .feedback-modal {
    display: none;
    z-index: 1;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    overflow: auto;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    }
    
    .feedback-content {
    background-color: #fff;
    margin: 5.5% auto;
    padding: 35px;
    border-radius: 10px;
    border: 1px solid #666;
    max-width: 56%;
    height: 750px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    animation-name: modalopen;
    animation-duration: 0.4s;
    }
    
    /* 왼쪽 피드백 */
    .feedback-content h1 {
    color: #000;
    font-family: Pretendard;
    font-size: 23px;
    font-style: normal;
    font-weight: 400;
    }
    
    .feedback-content p {
    color: #aaa;
    font-size: 15px;
    font-weight: 350;
    letter-spacing: -0.3px;
    }
    
    .feedback-form {
    display: flex;
    }
    
    .feedback-left {
    float: left;
    border: 1px solid #ccc;
    border-radius: 20px;
    width: 50%;
    height: 530px;
    margin-top: 10px;
    padding: 20px;
    margin-bottom: 15px;
    }
    
    .feedback-image {
    max-width: 100%;
    max-height: 100%;
    }
    
    /* 폼 컨테이너 스타일 */
    .fifth-custom-checkbox {
    width: 50%;
    max-width: 450px;
    height: 530px;
    margin: auto;
    padding: 20px;
    background: #fbfbfb;
    border: 1px solid #ccc;
    border-radius: 20px;
    margin-top: 10px;
    }
    
    /* 폼 그룹 스타일 */
    .fifth-form-group {
    margin-top: 10px;
    }
    
    /* 라벨 스타일 */
    .fifth-label {
    color: #333;
    font-size: 16px;
    text-align: left;
    display: block;
    text-align: left;
    margin-left: 5px;
    margin-bottom: 6px;
    }
    
    /* 인풋 스타일 */
    .fifth-input {
    width: 100%;
    padding: 10px;
    height: 45px;
    border-radius: 7px;
    border: 0.5px solid #ccc;
    box-sizing: border-box;
    margin-bottom: 16px;
    }
    
    /* 선택 상태 스타일 */
    .fifth-input:focus {
    outline: none;
    border-color: #007bff;
    }
    
    /* 제출 버튼 스타일 */
    .fifth-submit-button {
    display: block;
    float: right;
    padding: 10px;
    background-color: #f87171;
    color: #fff;
    border-radius: 7px;
    width: 100px;
    height: 40px;
    font-family: Pretendard, sans-serif;
    font-size: 15px;
    font-weight: 500;
    line-height: normal;
    border: none;
    cursor: pointer;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
    transition: background 0.3s ease;
    }
    
    /* 제출 버튼 호버 효과 */
    .fifth-submit-button:hover {
    background: #0056b3;
    }
    
    .two-steps-previous{
    width: 100px;
    height: 40px;
    border-radius: 7px;
    color: #666;
    font-weight: 500;
    background-color: #f2f4f6;
    border: none;
    font-family: Pretendard, sans-serif;
    font-size: 15px;
    line-height: normal;
    cursor: pointer;
    transition: background 0.3s ease;
    }
    
    .two-steps-previous:hover {
    background-color: #1070ff25;
    }
    
    .required_pdf {
    color: #aaa;
    font-size: 15px;
    }
    
    
    /* 생성권 모달 창 */
    .purchase-modal {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    justify-content: center;
    align-items: center;
    z-index: 2; /* 중요: 생성권 모달이 모달 위에 나타날 수 있도록 z-index 설정 */
    }
    
    .purchase-modal-content {
    background: #fff;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
    position: relative;
    display: flex;
    flex-direction: column;
    max-width: 400px;
    width: 100%;
    }
    
    .close {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    font-size: 20px;
    color: #555;
    }
    
    .close:hover {
    color: #000;
    }
    
    .purchase-modal-content p {
    margin-bottom: 20px;
    }
    
    .purchase-modal-content button {
    margin-top: 10px;
    align-self: flex-end;
    padding: 10px 15px;
    border: none;
    background-color: #007BFF;
    color: #fff;
    cursor: pointer;
    border-radius: 5px;
    font-size: 14px;
    }
    
    .purchase-modal-content button:hover {
    background-color: #0056b3;
    }
    
    .purchase-modal-content button:active {
    background-color: #004080;
    }
    
    .open-modal-button {
    padding: 8px 12px;
    border: none;
    background-color: #007BFF;
    color: #fff;
    cursor: pointer;
    border-radius: 5px;
    font-size: 14px;
    }
    
    .open-modal-button:hover {
    background-color: #0056b3;
    }
    
    .open-modal-button:active {
    background-color: #004080;
    }
    
.poin_box:hover,
.poin_box:focus {
    background-color: #f87171;
    color: #fff;
    text-decoration: none;
    cursor: pointer;
    z-index: 10;
}